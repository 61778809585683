import React from 'react';
import Breadcrumb from '../common/breadcrumb';
import Footer from '../footer/footer';
import HeaderFour from '../header/headerFour';
import CheckoutSection from './checkoutSection';
import { useTranslation } from 'react-i18next';

const CheckoutComponent = () => {
    
    const { t } = useTranslation();

    return (
        <>
            <HeaderFour />
            <main>
                <Breadcrumb breadcrumbTitle={t('VerifyOrder')} breadcrumbSubTitle={t('VerifyOrder')} />
                <CheckoutSection />
            </main>
            <Footer />
        </>
    );
};

export default CheckoutComponent;