import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { BASE_URL, CDN_URL } from '../../../config';
import * as Yup from 'yup'
import axios from 'axios';
import { setLoginAction } from '../../redux/auth/authSlice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
const RegistrationSection = ({ source, setFormType }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const confirmExit = (e) => {
            // Personaliza el mensaje de confirmación según tus necesidades
            const confirmationMessage = '¿Estás seguro de que deseas abandonar esta página sin guardar los cambios?';

            // Muestra el mensaje de confirmación al usuario
            e.preventDefault();
            e.returnValue = confirmationMessage;

            // Devuelve el mensaje de confirmación personalizado
            return confirmationMessage;
        };

        // Agrega el evento beforeunload para mostrar el mensaje de confirmación
        window.addEventListener('beforeunload', confirmExit);

        // Limpia el evento cuando el componente se desmonta
        return () => {
            window.removeEventListener('beforeunload', confirmExit);
        };
    }, []);

    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: ""
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('El nombre es obligatorio'),
        lastName: Yup.string().required('El apellido es obligatorio'),
        email: Yup.string().email('El correo electrónico no es válido').required('El correo electrónico es obligatorio'),
        password: Yup.string().required('La contraseña es obligatoria'),
        confirmPassword: Yup.string().required('La confirmación de contraseña es obligatoria').oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
        acceptTerms: Yup.boolean().oneOf([true], 'You must accept the Terms and Conditions').required('Required')        
    });

    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            handleSubmitGTM();
            await axios.post(BASE_URL + "/students", values)
            handleLogin(values);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleSubmitGTM = () => {
        if (typeof window !== 'undefined') {
            //Send event to GTM
            window.dataLayer.push({
                event: "sign_up",
                method: "Email"
            });
        }
    }

    const handleLogin = async (values) => {
        try {
            const payload = {
                email: values.email,
                password: values.password
            }
            const result = await axios.post(BASE_URL + "/auth", payload)
            localStorage.setItem('authToken', JSON.stringify(result.data));
            dispatch(setLoginAction(result.data));
            if (!source) {
                navigate("/student-profile")
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ errors }) => (
                <div className="signup-page-area pb-120">
                    <div className="signup-page-area-wrapper">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-6">
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-12 signup-form-wrapper">


                                                {source != 'checkout' &&
                                                    <div className='row mb-3'>
                                                        <div className='d-flex justify-content-center'>
                                                            <Link to="/"><img src={`${CDN_URL}/public/logo.png`} className='text-center' style={{ width: '200px' }} /></Link>
                                                        </div>
                                                    </div>
                                                }
                                                <h3 className='text-center mb-3'>{t('Register')}</h3>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='signup-input-wrapper'>
                                                            <Field type="text" name="firstName" placeholder={t('FirstName')} className={`${errors.firstName && 'border-danger'}`} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className='signup-input-wrapper'>
                                                            <Field type="text" name="lastName" placeholder={t('LastName')} className={`${errors.lastName && 'border-danger'}`} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='signup-input-wrapper'>
                                                            <Field type="email" name="email" placeholder={t('Email')} className={`${errors.email && 'border-danger'}`} />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <div className='signup-input-wrapper'>
                                                            <Field type="password" name="password" placeholder={t('Password')} className={`${errors.password && 'border-danger'}`} />
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className='signup-input-wrapper'>
                                                            <Field type="password" name="confirmPassword" placeholder={t('ReTypePassword')} className={`${errors.confirmPassword && 'border-danger'}`} />
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-12 text-center mb-3'>
                                                        <ErrorMessage component="div" className='text-danger' name="password" />
                                                        <ErrorMessage component="div" className='text-danger' name="confirmPassword" />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-check">
                                                            <Field type="checkbox" name="acceptTerms" className="mx-2" style={{boder: '1px solid black !important'}}/>
                                                            <label htmlFor="acceptTerms" className="form-check-label">
                                                                {t('Accept')} <Link to="/tos">{t('AcceptTOS')}</Link>
                                                            </label>
                                                            <ErrorMessage
                                                                component="div"
                                                                className="text-danger"
                                                                name="acceptTerms"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sing-buttom mb-20">
                                                    <button type='submit' disabled={loading} className="sing-btn">{loading ? `${t('Loading')}` : `${t('SignUp')}`}</button>
                                                </div>
                                                <div className="acount-login text-center">
                                                    {source != 'checkout' ? <span>¿{t('YouAreAlreadyAStudent')}? <a href="#" onClick={() => navigate("/login")}>{t('Login')}</a></span> : <span>¿{t('YouAreAlreadyAStudent')}? <a href="#" onClick={() => setFormType("Login")}>{t('Login')}</a></span>}

                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Formik>
    );
};

export default RegistrationSection;