import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL, CDN_URL } from '../../../config';
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux';
import { setLoginAction } from '../../redux/auth/authSlice';
import { useTranslation } from 'react-i18next';
const LoginSection = ({ source,setFormType }) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const [error, setError] = useState(null)
    const [showConfirmCode, setShowConfirmCode] = useState(false)
    const [sending, setSending] = useState(false);
    const [sendingConfirm, setSendingConfirm] = useState(false);

    const initialValues = {
        email: "",
        password: ""
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('El nombre es obligatorio'),
        password: Yup.string().required('La contraseña es obligatorio'),
    });

    const handleSubmit = async (values) => {
        try {
            handleSubmitGTM();
            const payload = {
                email: values.email,
                password: values.password
            }
            const result = await axios.post(BASE_URL + "/auth", payload)
            
            const {jwtToken, userData} = result.data;
            const payloadRedux = {
                jwtToken: jwtToken,
                userData:  userData
            }
            
            dispatch(setLoginAction(payloadRedux));
            localStorage.setItem('authToken', JSON.stringify(result.data));
            if(!source){
                navigate("/student-profile")
            }
        } catch (error) {
            if(error.response.data == 'User is not confirmed.'){
                resendConfirmCode(values)
                setShowConfirmCode(true);
            }
            setError(error.response.data)
        }
    }

    const handleConfirmCode = async (values) => {
        try {
            setSendingConfirm(true)
            const payload = {
                email: values.email,
                confirmationCode: values.confirmationCode
            }
            const result = await axios.post(BASE_URL + "/auth/confirm", payload)
            setSendingConfirm(false)
            handleLogin(values);
        } catch (error) {
            setSendingConfirm(false)
        }
    }

    const resendConfirmCode = async (values) => {
        
        try {
            setSending(true)
            const payload = {
                email: values.email
            }
            await axios.post(BASE_URL + "/auth/resend", payload)
            setSending(false)
        } catch (error) {
            setSending(false)
        } finally {
            setSending(false)
        }
    }

    const handleLogin = async (values) => {
        try {
            const payload = {
                email: values.email,
                password: values.password
            }
            const result = await axios.post(BASE_URL + "/auth", payload)
            
            localStorage.setItem('authToken', JSON.stringify(result.data));
            dispatch(setLoginAction(result.data));
            if(!source){
                navigate("/student-profile")
            }
        } catch (error) {

        }
    }

    const handleSubmitGTM = () => {
        if (typeof window !== 'undefined') {
            //Send event to GTM
            window.dataLayer.push({
                event: "login",
                method: "Email"
            });
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values }) => (
                <div className="signin-page-area  pb-120">
                    <div className="signin-page-area-wrapper">
                        <div className="container">
                            <div className="row justify-content-center">
                                {source == 'checkout' && !showConfirmCode &&
                                    <div className="col-xl-6 col-lg-6">
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="signup-form-wrapper">
                                                        
                                                    <h3 className='text-center mb-3'>{t('Login')}</h3>
                                                        <div className="signup-wrapper">
                                                            <Field type="text" name="email" placeholder={t('Email')} />
                                                            <ErrorMessage component="div" className='text-danger' name="email"/>
                                                        </div>
                                                        <div className="signup-wrapper">
                                                            <Field type="password" name="password" placeholder={t('Password')} />
                                                            <ErrorMessage component="div" className='text-danger' name="password"/>
                                                        </div>
                                                        {error && <div className='text-danger text-center m-2'>{error}</div>}
                                                        <div className="sing-buttom mb-20">
                                                            <button type="submit" disabled={isSubmitting} className="sing-btn">{isSubmitting ? `${t('Loading')}...` : 'Login'}</button>
                                                        </div>
                                                        <div className="registered wrapper">
                                                            <div className="not-register">
                                                                <span>{t('NotRegisterYet')}?</span><span><a href="#" onClick={()=>setFormType("Signup")}>{t('Register')}</a></span>
                                                            </div>
                                                            <div className="forget-password">
                                                                <Link to="/forgot-password">{t('ForgotPassword')}</Link>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                }
                                {!source && !showConfirmCode &&
                                    <div className="col-xl-10 col-lg-10">
                                        <Form>
                                            <div className="row justify-content-center pt-120">
                                                
                                                <div className="col-md-6">
                                                    <div className='d-flex justify-content-center mt-3'>
                                                        <Link to="/"><img src={`${CDN_URL}/public/logo.png`} className='text-center' style={{width: '200px'}} /></Link>
                                                    </div>
                                                
                                                    <div className="signup-form-wrapper">
                                                        <div className="signup-wrapper">
                                                            <Field type="text" name="email" placeholder={t('Email')} />
                                                        </div>
                                                        <div className="signup-wrapper">
                                                            <Field type="password" name="password" placeholder={t('Password')} />
                                                        </div>
                                                        {error && <div className='text-danger text-center m-2'>{error}</div>}
                                                        <div className="sing-buttom mb-20">
                                                        <button type="submit" disabled={isSubmitting} className="sing-btn">{isSubmitting ? `${t('Loading')}...` : 'Login'}</button>
                                                        </div>
                                                        <div className="registered wrapper">
                                                            <div className="not-register">
                                                                <span>{t('NotRegisterYet')}?</span><span><Link to="/registration">{t('Register')}</Link></span>
                                                            </div>
                                                            <div className="forget-password">
                                                                <Link to="/forgot-password">{t('ForgotPassword')}</Link>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>

                                    </div>
                                }
                                {showConfirmCode &&
                                <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="signup-form-wrapper">
                                        <h3 className='text-center mb-3'>{t('ConfirmationCode')}</h3>
                                        <div className="signup-wrapper">
                                            <Field type="text" name="confirmationCode" placeholder={t('ConfirmationCode')} required />
                                            <button type="button" onClick={()=>resendConfirmCode(values)} disabled={sending}>{sending ? `${t('Loading')}...` : 'Reenviar código'}</button>
                                            <ErrorMessage component="div" className='text-danger' name="confirmationCode"/>
                                        </div>
                                        <div className="sing-buttom mb-20">
                                        <button type='button' onClick={()=>handleConfirmCode(values)} disabled={sendingConfirm || values.confirmationCode == null || values.confirmationCode == ""} className="sing-btn">{sendingConfirm ? `${t('Loading')}...` : 'Confirmar' }</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>

    );
};

export default LoginSection;