import * as React from "react"
import Layout from "../../components/layout"
import CheckoutMain from "../../components/checkout"
import {graphql} from 'gatsby';
const CheckoutPage = () => (
  <Layout>
    <CheckoutMain />
  </Layout>
)

export default CheckoutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
